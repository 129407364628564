import actionTypes from '../../action-types/breedTypes'
import { http } from '../../../helpers'
import { toast } from 'react-toastify'
import { apiConfig } from '../../../api/api.config'

export const getBreeds = () => (dispatch) => {
  dispatch({ type: actionTypes.BREEDS_LOADING, loading: true })
  dispatch({ type: actionTypes.BREEDS_ERROR, error: null })

  http
    .get(apiConfig.getBreeds)
    .then(response => {
        dispatch({ type: actionTypes.BREEDS_LOADING, loading: false })
        dispatch({ type: actionTypes.BREEDS_ERROR, error: null })
      if (response.data) {
        dispatch({
          type: actionTypes.GETTING_BREEDS_INFO,
          breeds: response.data
        })
      }
    })
    .catch(err => {
      // toast.error(err.message);
      dispatch({ type: actionTypes.BREEDS_LOADING, loading: false })
      dispatch({ type: actionTypes.BREEDS_ERROR, error: err })
    })
}

import actionTypes from '../../action-types/friendsTypes'
import { http } from '../../../helpers'
import { toast } from 'react-toastify'
import { apiConfig } from '../../../api/api.config'

export const getFriends = () => (dispatch) => {
  dispatch({ type: actionTypes.FRIENDS_LOADING, loading: true })
  dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })

  http
    .get(apiConfig.friends.get)
    .then(response => {
        dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
        dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
      if (response.data) {
        dispatch({
          type: actionTypes.GETTING_FRIENDS_INFO,
          data: response.data
        })
      }
    })
    .catch(err => {
      dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
      dispatch({ type: actionTypes.FRIENDS_ERROR, error: err })
    })
}

export const deleteFriend = (id) => (dispatch) => {
    dispatch({ type: actionTypes.FRIENDS_LOADING, loading: true })
    dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
    const data = {
        friendId: id
    }
    return http
      .delete(apiConfig.friends.delete, {data})
      .then(response => {
          dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
          dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
        if (response.data) {
          dispatch({
            type: actionTypes.DELETE_FRIEND
          })
          dispatch(getFriends())
        }
      })
      .catch(err => {
        dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
        dispatch({ type: actionTypes.FRIENDS_ERROR, error: err })
      })
}

export const getFriendsRequests = () => (dispatch) => {
    dispatch({ type: actionTypes.FRIENDS_LOADING, loading: true })
    dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
  
    return http
      .get(apiConfig.friends.requests)
      .then(response => {
          dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
          dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
        if (response?.data) {
          dispatch({
            type: actionTypes.GETTING_FRIENDS_REUQESTS,
            data: response.data
          })
        }
        return response
      })
      .catch(err => {
        dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
        dispatch({ type: actionTypes.FRIENDS_ERROR, error: err })
        throw err
      })
}

//**** Temporary */
export const getAllUsers = () => (dispatch) => {
    dispatch({ type: actionTypes.FRIENDS_LOADING, loading: true })
    dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
  
    http
      .get('/all-users')
      .then(response => {
          dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
          dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
        if (response.data) {
          dispatch({
            type: actionTypes.ALL_USERS,
            data: response.data
          })
        }
      })
      .catch(err => {
        dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
        dispatch({ type: actionTypes.FRIENDS_ERROR, error: err })
      })
}

export const sendFriendRequest = (id) => (dispatch) => {
    dispatch({ type: actionTypes.FRIENDS_LOADING, loading: true })
    dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
    const data = {
        to: id
    }
    return http
      .post(apiConfig.friends.send, data)
      .then(response => {
          dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
          dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
        if (response.data) {
          dispatch({
            type: actionTypes.SEND_FRIEND_REQUEST
          })
        }
      })
      .catch(err => {
        dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
        dispatch({ type: actionTypes.FRIENDS_ERROR, error: err })
      })
}

export const acceptFriendRequest = (id) => (dispatch) => {
    dispatch({ type: actionTypes.FRIENDS_LOADING, loading: true })
    dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
    const data = {
        senderId: id
    }
    return http
      .post(apiConfig.friends.accept, data)
      .then(response => {
          dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
          dispatch({ type: actionTypes.FRIENDS_ERROR, error: null })
        if (response.data) {
          dispatch({
            type: actionTypes.ACCEPT_FRIEND
          })
          dispatch(getFriendsRequests())
          dispatch(getFriends())
        }
      })
      .catch(err => {
        dispatch({ type: actionTypes.FRIENDS_LOADING, loading: false })
        dispatch({ type: actionTypes.FRIENDS_ERROR, error: err })
      })
}
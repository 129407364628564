import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { apiConfig } from '../api/api.config'
import { http } from '../helpers'
import Cookies from 'js-cookie'

// Функція для завантаження перекладів з API
const fetchTranslations = async (lang) => {
  const response = await http.get(`${apiConfig.translations}/${lang}`)
  return response.data
}

const defaultLanguage = Cookies.get('language') || 'en'

i18n
  .use(initReactI18next) // передає інстанс i18n в react-i18next
  .init({
    lng: defaultLanguage, // Мова за замовчуванням
    fallbackLng: 'en', // Резервна мова
    debug: false,
    resources: {}, // Початково порожній ресурс, переклади будуть додані пізніше

    interpolation: {
      escapeValue: false, // React самостійно захищає від XSS
    },
  })

// Функція для зміни мови та завантаження перекладів
const changeLanguage = async (lang) => {
  const translations = await fetchTranslations(lang)
  i18n.addResourceBundle(lang, 'translation', translations)
  i18n.changeLanguage(lang)
  Cookies.set('language', lang, { expires: 7 }); // Зберігаємо обрану мову в cookie на 7 днів
};

// Функція для генерації посилань з урахуванням обраної мови
const generateLink = (path) => {
  const currentLang = Cookies.get('language') || 'en';
  return currentLang === 'en' ? path : `/ua${path}`;
};

export { changeLanguage, generateLink };
export default i18n

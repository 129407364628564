import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import cross from "../../assets/img/icons/cross-white.svg";
import Button from "../../ui/Button";
import { useDropzone } from "react-dropzone";
import { uploadProfilePhoto, deleteProfilePhoto } from "../../store/actions/profile/index";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { storageUrl } from "../../utils/Utils";
import downloadIcon from "../../assets/img/icons/download_icon.svg";
import FormLoader from "../../ui/FormLoader";
import avatar from "../../assets/img/icons/avatar-example.png";
import { getUserInfo } from "../../store/actions/auth";
import { useTranslation } from "react-i18next";

const PhotoForm = () => {
    const { t } = useTranslation();
    const [deletePhotoPopup, setDeletePhotoPopup] = useState(false);
    const [photoPopup, setPhotoPopup] = useState(false);
    const [isPhotoUpdated, setPhotoUpdated] = useState(false);
    const [isPhotoDeleted, setPhotoDeleted] = useState(false);
    const [sizeRange, setSize] = useState(1);
    const [angleRange, setAngle] = useState(0);
    const [files, setFiles] = useState([]);
    const profilePhotoLoading = useSelector(state => state.profile.profilePhotoLoading);
    const profilePhotoError = useSelector(state => state.profile.profilePhotoError);
    const currentUser = useSelector(state => state.auth.currentUser);
    const dispatch = useDispatch();
    const imageRef = useRef(null);
    Modal.setAppElement("#root");

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            openPhotoModal(true);
        }
    });

    const {
        handleSubmit
    } = useForm();

    const thumbScale = 1 + (sizeRange - 1) / 5;
    const thumbAngle = angleRange;
    const thumbsStyle = {
        transform: `scale(${thumbScale}) rotate(${thumbAngle}deg)`
    };

    const thumbs = files.map(file => (
        <img
            ref={imageRef}
            src={file.preview}
            key={file.name}
            alt="Thumb"
            style={thumbsStyle}
            onLoad={() => { URL.revokeObjectURL(file.preview); }}
        />
    ));

    useEffect(() => {
        const onPhotoChange = () => {
            if (files?.length) {
                setSize(1);
                setAngle(0);
            }
        };
        onPhotoChange();
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const openDeletePhotoModal = (e) => {
        e.preventDefault();
        setDeletePhotoPopup(true);
    };

    const closeDeletePhotoModal = () => {
        setDeletePhotoPopup(false);
    };

    const openPhotoModal = () => {
        setPhotoPopup(true);
    };

    const closePhotoModal = () => {
        setPhotoPopup(false);
    };

    const onDeletePhoto = () => {
        dispatch(deleteProfilePhoto()).then(response => {
            console.log("Фото успішно видалене!", response);
            setPhotoDeleted(true);
            dispatch(getUserInfo());
            setTimeout(() => {
                setPhotoDeleted(false);
                closePhotoModal();
            }, 3000);
        }).catch(error => {
            console.error("Помилка видалення фото:", error);
        });
    };

    const handleRotationChange = (e) => {
        const newRotation = e.target.value;
        setAngle(newRotation);
        updateTransform();
    };

    const handleScaleChange = (e) => {
        const newScale = e.target.value;
        setSize(newScale);
        updateTransform();
    };

    const updateTransform = () => {
        imageRef.current.style.transform = `rotate(${angleRange}deg) scale(${thumbScale})`;
    };

    const saveImage = async () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = imageRef.current;
    
        const width = image.naturalWidth;
        const height = image.naturalHeight;
    
        const scaleFactor = 0.5; 
        canvas.width = Math.floor(width * thumbScale * scaleFactor);
        canvas.height = Math.floor(height * thumbScale * scaleFactor);
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((thumbAngle * Math.PI) / 180);
        ctx.scale(thumbScale * scaleFactor, thumbScale * scaleFactor);
        ctx.drawImage(image, -width / 2, -height / 2, width, height);
    
        canvas.toBlob((blob) => {    
            const formData = new FormData();
            formData.append("image", blob, "image.jpg");
            dispatch(uploadProfilePhoto(formData)).then(response => {
                console.log("Фото успешно обновлено!", response);
                setPhotoUpdated(true);
                dispatch(getUserInfo());
                setTimeout(() => {
                    setPhotoUpdated(false);
                    closePhotoModal();
                }, 3000);
            }).catch(error => {
                console.error("Ошибка обновления фото:", error);
            });
        }, "image/jpeg", 0.8); 
    };
    

    return (
        <>
            <div className="upload-photo">
                <div {...getRootProps({ className: "dropzone" })}>
                    <div className="upload-photo_left">
                        <div className="userphoto">
                            {thumbs.length ? thumbs : currentUser?.image ?
                                <img src={`${storageUrl}/${currentUser.image}`} alt={currentUser.name} /> :
                                <img src={avatar} alt="Avatar" />
                            }
                        </div>
                        <div className="upload-file">
                            <input {...getInputProps()} />
                            <div className="upload-file__info">
                                <img src={downloadIcon} alt="upload" />
                                <div className="upload-file__text">
                                    <span className="subtitle_medium desktop"><b>{t("profile.click_upload")}</b> {t("profile.drug_drop_text")}</span>
                                    <span className="subtitle_small mobile">{t("profile.upload_new_photo")}</span>
                                    <span className="filesize subtitle_small">{t("profile.format_photo")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="delete" onClick={openDeletePhotoModal}>
                    <span className="subtitle_medium">{t("profile.delete_photo")}</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10 4C9.44772 4 9 4.44772 9 5H15C15 4.44772 14.5523 4 14 4H10ZM17 7H7V17C7 18.6569 8.34315 20 10 20H14C15.6569 20 17 18.6569 17 17V7ZM5 7V17C5 19.7614 7.23858 22 10 22H14C16.7614 22 19 19.7614 19 17V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17C17 3.34315 15.6569 2 14 2H10C8.34315 2 7 3.34315 7 5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM14 9C14.5523 9 15 9.44772 15 10V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V10C13 9.44772 13.4477 9 14 9Z" fill="#94A8B4" />
                    </svg>
                </div>
            </div>

            <Modal
                isOpen={deletePhotoPopup}
                onRequestClose={closeDeletePhotoModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content">
                    <div className="popup__close" onClick={closeDeletePhotoModal}><img src={cross} alt="Close" /></div>
                    <div className="popup__delete-photo">
                        <h2 className="popup__heading h2">{t("profile.delete_photo")}</h2>
                        <span className="popup__delete-photo_descr subtitle_big">{t("profile.question_delete_profile_photo")}</span>
                        <div className="btns-flex">
                            <Button onClick={closeDeletePhotoModal} tag="button" type="secondary">{t("general.cancel")}</Button>
                            <Button tag="button" type="primary" onClick={onDeletePhoto}>{t("general.configrm_button_text")}</Button>
                        </div>
                        {isPhotoDeleted && <p className="success">{t("general.photo_deleted_successfully")}</p>}
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={photoPopup}
                onRequestClose={closePhotoModal}
                className="popup"
                overlayClassName="popup__overlay"
                shouldCloseOnOverlayClick={true}
            >
                <div className="popup__content">
                    {profilePhotoLoading && <FormLoader />}
                    <div className="popup__close" onClick={closePhotoModal}><img src={cross} alt="Close" /></div>
                    <form className="popup__upload-step upload-step"
                        onSubmit={handleSubmit(saveImage)}
                        encType="multipart/form-data"
                    >
                        <h2 className="popup__heading h2">{t("profile.last_step")}</h2>
                        <span className="popup__description subtitle_medium">{t("profile.upload_image_now_text")}</span>
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="change-photo-profile">
                                {thumbs.length ? thumbs : (
                                    <div className="change-photo-profile__text-part">
                                        <div className="text_small">
                                            <p className="subtitle_medium">{t("profile.click_upload")}</p>
                                            <span>{t("profile.drug_drop_text")}</span><br />
                                            {t("profile.format_photo")}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="photo-ranges">
                            <div className="photo-range">
                                <span className="photo-range__title subtitle_medium">{t("profile.size")}</span>
                                <div className="photo-range__input">
                                    <input type="range" min="1" max="9" value={sizeRange} id="range_size" onChange={handleScaleChange} />
                                </div>
                            </div>
                            <div className="photo-range">
                                <span className="photo-range__title subtitle_medium">{t("profile.angular")}</span>
                                <div className="photo-range__input">
                                    <input type="range" min="0" max="360" id="range_angle" value={angleRange} onChange={handleRotationChange} />
                                </div>
                            </div>
                        </div>
                        <div className="btns-flex">
                            {files?.length ? <Button tag="button">{t("profile.save")}</Button> : ""}
                        </div>
                        {profilePhotoError && <p className="form__error">{t("general.wrong_message")}</p>}
                        {isPhotoUpdated && <p className="success">{t("general.photo_update_successfully")}</p>}
                    </form>
                </div>
            </Modal>
        </>
    );
};
export default PhotoForm;

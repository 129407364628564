import actionTypes from '../../action-types/'
import { http } from '../../../helpers'
import { toast } from 'react-toastify'
import { apiConfig } from '../../../api/api.config'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

export const loginWithJWT = (user, t) => async (dispatch) => {
  dispatch({ type: actionTypes.AUTH_LOADING, loading: true })
  dispatch({ type: actionTypes.AUTH_ERROR, error: null })
  return http
    .post(apiConfig.login, {
      email: user.email,
      password: user.password
    })
    .then(response => {
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false })
      dispatch({ type: actionTypes.AUTH_ERROR, error: null })
      console.log(response)
      if (response?.data) {
        dispatch({
          type: actionTypes.LOGIN_USER,
          token: response.data.access_token
        })
        return response
      }
    })
    .catch(err => {
      console.log(err.response.status)
      if (err.response.status == 422) {
        toast.error(t('general.invalid_credentials'));
      } else {
        toast.error(t('general.failed_reload_again'));
      }
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false })
      dispatch({ type: actionTypes.AUTH_ERROR, error: err })
      // throw err.response.data.errors
    })
}

export const signupWithJWT = (user, t) => async (dispatch) => {
  await dispatch({ type: actionTypes.AUTH_LOADING, loading: true })
  await dispatch({ type: actionTypes.AUTH_ERROR, error: null })
  const userForLogin = {
    email: user.email,
    password: user.password
  }
  await http
    .post(apiConfig.signup, {
      name: user.name,
      email: user.email,
      password: user.password,
      pet_name: user.pet_name,
      pet_breed: user.pet_breed,
      pet_gender: user.pet_gender,
      email_subscription: user.email_subscription,
    })
    .then(async response => {
      console.log(response)
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false })
      dispatch({ type: actionTypes.AUTH_ERROR, error: null })
      if (response.data) {
        // alert(response.data.verification_code)
        dispatch(loginWithJWT(userForLogin))
        // dispatch({
        //   type: actionTypes.SIGNUP_USER,
        //   currentUser: response.data.user,
        //   userForLogin: userForLogin
        // })
      }
    })
    .catch(err => {
      toast.error(t('general.failed_reload_again'));
      console.log(err)
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false })
      dispatch({ type: actionTypes.AUTH_ERROR, error: err })
    })
}

export const verifyEmail = (data) => async(dispatch) => {
  await dispatch({ type: actionTypes.AUTH_LOADING, loading: true })
  await dispatch({ type: actionTypes.AUTH_ERROR, error: null })
  http.post(apiConfig.verifyEmail, data).then(async response => {
    await dispatch({ type: actionTypes.AUTH_LOADING, loading: false })
    await dispatch({ type: actionTypes.AUTH_ERROR, error: null })
    if(response.data) {
      console.log(response)
      dispatch({
        type: actionTypes.VERIFY_EMAIL,
        currentUser: response.data.user,
      })
    } 
  }).catch(err => {
      // toast.error(err.message);
      dispatch({ type: actionTypes.AUTH_ERROR, error: err })
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
  })
}

export const getUserInfo = () => (dispatch) => {
  dispatch({ type: actionTypes.AUTH_LOADING, loading: true })
  dispatch({ type: actionTypes.AUTH_ERROR, error: null })
  return http.get(apiConfig.me).then(response => {
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false })
      dispatch({ type: actionTypes.AUTH_ERROR, error: null })
      if (response?.data) {
        dispatch({
          type: actionTypes.GETTING_USER_INFO,
          currentUser: response.data
        })
      }
      return response
    })
    .catch(err => {
      // toast.error(err.message);
      dispatch({ type: actionTypes.AUTH_ERROR, error: err })
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false })
      throw err
    })
}

export const addOnline = () => () => {
  http.put(apiConfig.addOnline).then(response => {
      console.log(response?.data)
    })
    .catch(err => {
      // toast.error(err.message)
      throw err
    })
}

export const refreshAuthToken = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(apiConfig.url + '/refresh', {}, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response?.status === 200) {
      const access_token = response.data.access_token;
      localStorage.setItem('token', access_token);
      return access_token;
    } else {
      throw new Error('Failed to refresh token');
    }
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = (data) => (dispatch) => {
  dispatch({ type: actionTypes.AUTH_LOADING, loading: true })
  dispatch({ type: actionTypes.AUTH_ERROR, error: null })
  return http.post(apiConfig.forgotPassword, data).then(response => {
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false })
      dispatch({ type: actionTypes.AUTH_ERROR, error: null })
      return response
    })
    .catch(err => {
      // toast.error(err.message);
      dispatch({ type: actionTypes.AUTH_ERROR, error: err })
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false })
      throw err
    })
}
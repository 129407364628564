import { useTranslation } from 'react-i18next';
import { generateLink } from '../utils/i18n';

const Offerta = () => {
    const { t } = useTranslation();

    return (
        <div className='content'>
            <ul className="tabs">
                <li data-tab="terms">
                    <a href={generateLink('/terms-and-conditions')}>{t('general.terms_and_conditions_tab')}</a>
                </li>
                <li data-tab="privacy">
                    <a href={generateLink('/privacy-policy')}>{t('general.privacy_policy_tab')}</a>
                </li>
                <li className="active" data-tab="offerta">
                    <a href={generateLink('/offerta')}>{t('general.offerta_tab')}</a>
                </li>
            </ul>
            <div className="container">
                <div className="text_privacy" dangerouslySetInnerHTML={{ __html: t('privacy.offerta_text') }} />
            </div>
        </div>
    );
}

export default Offerta;

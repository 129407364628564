import Input from "../ui/Input";
import { useForm } from 'react-hook-form';
import Button from "../ui/Button";
import { apiConfig } from '../api/api.config.js';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next'

const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        register, 
        handleSubmit, 
        watch, 
        formState: { errors }
    } = useForm();

    const password = watch('password'); 

    const onSubmit = async (data) => {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const resetToken = urlParams.get('token');
            const response = await fetch(apiConfig.url + '/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...data, token: resetToken }), 
            });

            if (response.ok) {
                const result = await response.json();
                toast.success(t('general.request_sent_successfully'))
                navigate('/');
            } else {
                const error = await response.json();
                toast.error(t('general.failed_try_again'))
            }
        } catch (error) {
            toast.error(t('general.failed_try_again'))
        }
    };

    return (
        <div className='content'>
            <div className="form-wrap">
                <div className="popup__change-email">
                    <h2 className='popup__heading h2'>Recover your password</h2>
                    <span className='popup__description subtitle_medium'>Enter a new password and confirm it</span>
                    <form className='popup__form' onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            placeholder="Enter new password"
                            name="password"
                            type="password"
                            register={register}
                            validationSchema={{
                                required: "Password is required",
                                minLength: {
                                    value: 6,
                                    message: "Password must be at least 6 characters long"
                                }
                            }}
                            errors={errors}
                        />
                        <Input
                            placeholder="Confirm new password"
                            name="password_confirmation"
                            type="password"
                            register={register}
                            validationSchema={{ 
                                required: "Please confirm your password"
                            }}
                            passwordToCompare={password}
                            errors={errors}
                        />
                        <Button
                            tag="button" 
                            type="primary"
                        >Confirm</Button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;

import actionTypes from '../../action-types/videochatTypes'
import { http } from '../../../helpers'
import { toast } from 'react-toastify'
import { apiConfig } from '../../../api/api.config'

export const getVideochatPeerId = ({breed_id, exclusion_users = []}) => async (dispatch) => {
  dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: true })
  dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
  return http
    .post(apiConfig.videochat.getPeerId, {
        breed_id: breed_id,
        exclusion_users: exclusion_users
    })
    .then(response => {
        dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
        dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
      if (response?.data) {
        dispatch({
          type: actionTypes.GETTING_REMOTE_PEER,
          id: response.data.randomUser?.id
        })
      }
      return response
    })
    .catch(err => {
      dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
      dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: err })
      throw err
    })
}

export const setVideochatBreed = (breedId) => (dispatch) => {
  dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: true })
  dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
  
  http
    .patch(apiConfig.videochat.setBreed, {
        breed_id: breedId
    })
    .then(response => {
        dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
        dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
      if (response.data) {
        dispatch({
          type: actionTypes.SET_BREED,
          data: response.data
        })
      }
    })
    .catch(err => {
      dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
      dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: err })
    })
}

export const endCall = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: true })
  dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
  return http
    .post(apiConfig.videochat.endCall, data)
    .then(response => {
        dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
        dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
      if (response?.data) {
        dispatch({
          type: actionTypes.END_CALL,
        })
      }
      return response
    })
    .catch(err => {
      dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
      dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: err })
      throw err
    })
}

export const isOnCall = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: true })
  dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
  return http
    .patch(apiConfig.videochat.isOnCall, data)
    .then(response => {
        dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
        dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
      if (response?.data) {
        dispatch({
          type: actionTypes.IS_ON_CALL,
        })
      }
      return response
    })
    .catch(err => {
      dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
      dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: err })
      throw err
    })
}

export const saveVideo = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: true })
  dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
  return http
    .post(apiConfig.videochat.saveVideo, data)
    .then(response => {
        dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
        dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
      return response
    })
    .catch(err => {
      dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
      dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: err })
      throw err
    })
}

export const callFriend = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: true })
  dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
  return http
    .post(apiConfig.videochat.callFriend, data)
    .then(response => {
        dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
        dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
        if (response?.data) {
          dispatch({
            type: actionTypes.FRIEND_ON_CALL,
            data: response?.data
          })
        }
        return response
    })
    .catch(err => {
      dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
      dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: err })
      throw err
    })
}

export const getVideoLimits = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: true })
  dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
  return http
    .get(apiConfig.videochat.getVideoLimits, data) 
    .then(response => {
        dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
        dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
      return response
    })
    .catch(err => {
      dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
      dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: err })
      throw err
    })
}

export const getFriendLimit = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: true })
  dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
  return http
    .get(apiConfig.videochat.getFriendLimit, data) 
    .then(response => {
        dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
        dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
      return response
    })
    .catch(err => {
      dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
      dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: err })
      throw err
    })
}

export const getTimeLimit = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: true })
  dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
  return http
    .get(apiConfig.videochat.getTimeLimit, data)
    .then(response => {
        dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
        dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
      return response
    })
    .catch(err => {
      dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
      dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: err })
      throw err
    })
}



export const addReport = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: true })
  dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
  return http
    .post(apiConfig.videochat.addReport, data)
    .then(response => {
        dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
        dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: null })
      return response
    })
    .catch(err => {
      dispatch({ type: actionTypes.VIDEOCHAT_LOADING, loading: false })
      dispatch({ type: actionTypes.VIDEOCHAT_ERROR, error: err })
      throw err
    })
}
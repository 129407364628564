import { useTranslation } from 'react-i18next';
import { generateLink } from '../utils/i18n';
const Privacy = () => {
    const { t } = useTranslation();
    return <div className='content'>
        <div class="container">
            <ul className="tabs">
                <li data-tab="terms">
                    <a href={generateLink('/terms-and-conditions')}>{t('general.terms_and_conditions_tab')}</a>
                </li>
                <li className="active" data-tab="privacy">
                    <a href={generateLink('/privacy-policy')}>{t('general.privacy_policy_tab')}</a>
                </li>
                <li data-tab="offerta">
                    <a href={generateLink('/offerta')}>{t('general.offerta_tab')}</a>
                </li>
            </ul>
            <div class="text_privacy" dangerouslySetInnerHTML={{ __html: t('privacy.text_privacy_policy') }} />
        </div>
    </div>
}

export default Privacy
import { Link, useSearchParams } from 'react-router-dom';
import additionalInfo from '../assets/img/icons/additional_infoIcon.svg';
import additionalInfoDark from '../assets/img/icons/additional_infoIconDark.svg';
import { formatDateShort, videoStorageUrl } from '../utils/Utils';
import { useDispatch } from 'react-redux';
import { deleteVideo } from '../store/actions/saved-videos';
import { useTranslation } from 'react-i18next';
import {useCookies} from 'react-cookie'
import { toast } from 'react-toastify'

const VideoCard = ({ data }) => {
    const videoSize = (data?.size / 1024 / 1024).toFixed(2);
    const videoDate = formatDateShort(data.updated_at);
    const downloadUrl = videoStorageUrl + data?.url;
    const [cookies] = useCookies(['theme', 'language'])
    const [searchParams] = useSearchParams()

    const dispatch = useDispatch();

    const onDelete = (e) => {
        e.preventDefault();
        dispatch(deleteVideo(data.id)).then(() => {
            toast.success(t('general.video_removed_successfully'))
        }).catch(() => {
            toast.error(t('general.failed_try_again'))
        });
    };

    const downloadVideo = async (e) => {
        const device = searchParams.get('device')
        e.preventDefault()
        if (device === 'app') {
            window.open(downloadUrl, '_blank')
            return
        }
        try {
            const response = await fetch(downloadUrl);
            if (!response.ok) throw new Error('Network response was not ok');
    
            const urlParts = downloadUrl.split('.');
            const extension = urlParts[urlParts.length - 1];
    
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `parrot-${videoDate}.${extension}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url); 
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    };
    

    const openInNewTab = () => {
        window.open(downloadUrl, '_blank');
    };

    const { t } = useTranslation();

    return (
        <div className='video-item'>
            <div className='video-item__info' onClick={openInNewTab}>
                <div className='video-item__info-line'>
                    <span className='selector subtitle_medium'>{t('subscriptions.date')}</span>
                    <span className='text text_medium'>{videoDate}</span>
                </div>
                <div className='video-item__info-line'>
                    <span className='selector subtitle_medium'>{t('videos.size_title')}</span>
                    <span className='text text_medium'>{videoSize} {t('videos.video_size')}</span>
                </div>
            </div>
            <div className='additional-btn'>
                <img src={cookies?.theme === 'dark' ? additionalInfo : additionalInfoDark} alt="additional info button" />
                <ul className='video-item__dropdown dropdown'>
                    <li>
                        <a href="/" onClick={downloadVideo}>{t('general.download')}</a>
                    </li>
                    <li>
                        <Link to="/" onClick={onDelete}>{t('general.delete')}</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default VideoCard;
